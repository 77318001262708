.definition-list {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 0.5rem;

  dt {
    font-size: 15px;
  }

  dd {
    font-weight: bold;
  }
}
